<template lang="pug">
Navbar
.padding-top.help
  .help-header.pricing.pb-5
    .fs-4.pricing-title.fw-bold.lh-sm.pb-2.text-center How can we help you?
    .container
      .row.height.d-flex.justify-content-center.align-items-center
        .col-md-6
          .search
            span.input-group-text
              img.ps-3(src="icons/search.svg")
              input.form-control(type='text' placeholder='Search Here' v-model="search")

.container.py-5
  BackHelp
  .row
    .col-md-12.py-5
      template(v-for="help in ContactList")
        .help-title.fs-5.fw-bold.pb-4 {{ help.title }}
        .help-details
          .description
            template(v-for="subhelp in help.description")
              p {{ subhelp.text }}
              .help-image.py-4(v-if="subhelp.imgType === true")
                img.img-fluid(:src="'/images/help/' + subhelp.img")
          .detail-explanation
            template(v-for="exp in help.explanation")
              .explanation-title.fs-5.fw-bold {{ exp.expTitle }}
              .explanation-description
                p {{ exp.expDesc }}
              .explanation-image.py-4(v-if="exp.imgType === true")
                img.img-fluid(:src="'/images/help/' + exp.img")
  BackHelp


Footer
</template>

<script>
import Navbar from "../../../components/TheNavbar";
import Footer from "../../../components/TheFooter";
import BackHelp from "../../../components/BackHelp";
import SwiperCore, { Navigation, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import "swiper/swiper-bundle.css";

SwiperCore.use([Navigation, Pagination, A11y]);

export default {
  name: "WhatIsKloser",
  components: { Footer, Navbar, BackHelp, Swiper, SwiperSlide },
  setup() {
    return {
      modules: [Pagination, Navigation],
    };
  },
  data() {
    return {
      tabs: [
        {
          title: "Step 1 Create a Kloser Account",
          id: "tabOne",
        },
        {
          title: "Step 2 Import Contacts from phonebook",
          id: "tabTwo",
        },
        {
          title: "Step 3 Complete your profile details",
          id: "tabThree",
        },
        {
          title: "Step 4 Create your product/service listings",
          id: "tabFour",
        },
        {
          title: "Step 5 Share website to your contacts",
          id: "tabFive",
        },
        {
          title: "Step 6 Subscription to Kloser",
          id: "tabSix",
        },
        {
          title: "Step 7 Start promoting your business",
          id: "tabSeven",
        },
        {
          title: "Step 8 Update lead information/status",
          id: "tabEight",
        },
      ],
      ContactList: [
        {
          id: "tabOne",
          title: "1.1 What is Kloser",
          description: [
            {
              text: "Kloser is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, SMS, email, and other popular chat apps.",
              imgType: false,
            },
          ],
          explanation: [
            {
              expTitle: "Easily manage your leads",
              expDesc:
                "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 1.png",
            },
            {
              expTitle: "The simplest Marketing advertisement tool ",
              expDesc:
                'The status of this lead. A newly added contact will be marked as "New" for 30 days by default. If there are activities between you and the lead, the status will change to "In progress". If the deal has been closed, you can change the status to "Closed"',
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 2.png",
            },
            {
              expTitle: "Build Your Unique Online Presence",
              expDesc:
                "This section displays all custom groups the client is a part of. Tap “Add new group”  to add the client to the group. This section will be empty if you haven’t created any groups yet.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 3.png",
            },
          ],
        },
        {
          id: "tabTwo",
          title: "1.2 What is Kloser",
          description: [
            {
              text: "Kloser is a mobile CRM built for sales professionals who run their businesses from their phone. It works with WhatsApp, SMS, email, and other popular chat apps.",
              imgType: false,
            },
          ],
          explanation: [
            {
              expTitle: "Easily manage your leads",
              expDesc:
                  "Notes are for you to remember details about the contact, such as their preferences. These are private to you, and never shown to your clients. This field is optional.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 1.png",
            },
            {
              expTitle: "The simplest Marketing advertisement tool ",
              expDesc:
                  'The status of this lead. A newly added contact will be marked as "New" for 30 days by default. If there are activities between you and the lead, the status will change to "In progress". If the deal has been closed, you can change the status to "Closed"',
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 2.png",
            },
            {
              expTitle: "Build Your Unique Online Presence",
              expDesc:
                  "This section displays all custom groups the client is a part of. Tap “Add new group”  to add the client to the group. This section will be empty if you haven’t created any groups yet.",
              imgType: true,
              img: "Kloser-LP-Banner-USP Image 3.png",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style>

</style>
